<template>
  <auth-container>
    <CHeading
      v-if="!isSend"
      as="h1"
      color="brand.900"
      fontSize="36px"
      fontWeight="600"
      marginBottom="80px"
    >
      Kirim Ulang Verifikasi
    </CHeading>
    <CBox w="100%" textAlign="left">
      <form v-if="!isSend" @submit.prevent="submit">
        <c-form-control marginBottom="30px">
          <c-form-label
            for="email"
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Email address
          </c-form-label>
          <c-input-group size="md">
            <c-input
              type="email"
              is-invalid
              error-border-color="red.300"
              id="email"
              placeholder="Masukkan Email"
              height="62px"
              v-model="email"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control marginBottom="30px">
          <CButton
            px="26px"
            py="18px"
            fontSize="18px"
            fontWeight="500"
            lineHeight="24px"
            borderRadius="58px"
            width="100%"
            h="auto"
            color="white"
            type="submit"
            variant-color="brand"
            variant="solid"
            :isDisabled="!email"
            :is-loading="isLoading"
            loading-text="Submitting..."
          >
            Kirim
          </CButton>
        </c-form-control>
      </form>
      <c-box v-if="isSend" text-align="center">
        <CImage
          src="/images/success-iso.png"
          alt="success"
          pos="relative"
          zIndex="1"
          mx="auto"
          mb="30px"
        />
        <CHeading
          as="h1"
          color="brand.900"
          fontSize="36px"
          fontWeight="600"
          marginBottom="30px"
        >
          Selamat
        </CHeading>
        <CText color="darkGray.900" fontSize="18px" lineHieght="27px">
          Permintaan terkirim, silakan cek email
        </CText>
        <CButton
          as="router-link"
          to="/"
          variant="outline"
          variant-color="brand"
          rounded="full"
          mt="40px"
        >
          Homepage
          <CImage
            ml="10px"
            :src="require('@/assets/icon-chevron-right-green.svg')"
          />
        </CButton>
      </c-box>
    </CBox>
  </auth-container>
</template>

<script>
import AuthContainer from "@/components/auth-container";
import { mapActions } from "vuex";

export default {
  name: "forgotPage",
  components: {
    AuthContainer,
  },
  data() {
    return {
      email: null,
      isLoading: false,
      isSend: false,
    };
  },
  methods: {
    ...mapActions({
      resendVerification: "auth/resendVerification",
    }),
    submit() {
      this.isLoading = true;
      this.resendVerification({ email: this.email })
        .then(() => {
          this.isLoading = false;
          this.isSend = true;
        })
        .catch((err) => {
          this.isLoading = false;
          this.isSend = false;
          this.$toast({
            title: "Failed",
            description: err.data.message,
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
  },
};
</script>

<style>
</style>
